
import { ExternalLink } from 'lucide-react';
import { useIsMobile } from '@/hooks/use-mobile';
import { Card } from "@/components/ui/card";

const PromotionalBanner = ({ className = "" }: { className?: string }) => {
  const isMobile = useIsMobile();

  return (
    <Card className={`relative border-2 border-blue-500 shadow-md overflow-hidden ${className}`}>
      <div className={`flex ${isMobile ? 'flex-col' : 'flex-row items-center justify-between'} p-4`}>
        <div className={`flex ${isMobile ? 'flex-col' : 'flex-row items-center'} gap-4`}>
          <img 
            src="/lovable-uploads/ce2ae355-7c2c-416c-ab6d-45e371fd5b14.png" 
            alt="Vattenfall" 
            className={`${isMobile ? 'w-auto h-16 object-contain' : 'w-auto h-16'}`}
            loading="lazy" 
          />
          
          <div className={`${isMobile ? 'text-center mt-1' : 'flex flex-col'}`}>
            <h4 className={`font-bold text-blue-800 ${isMobile ? 'text-sm' : 'text-base'}`}>Tarjous: Perusmaksut 0€/6kk</h4>
            <p className="text-xs text-slate-700">
              Vattenfall Tuntisähkö Mix perusmaksut nyt 0€ ensimmäisen 6kk ajaksi.
            </p>
          </div>
        </div>
        
        <div className={`${isMobile ? 'mt-3' : 'ml-4 flex-shrink-0'}`}>
          <a 
            href="https://go.adt267.com/t/t?a=1884560810&as=1498692683&t=2&tk=1&epi=testi-1" 
            target="_blank" 
            rel="noopener noreferrer"
            className={`text-${isMobile ? 'xs' : 'base'} bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded flex items-center gap-1.5 transition-colors whitespace-nowrap justify-center shadow-md font-medium`}
          >
            Katso lisää
            <ExternalLink className={`h-${isMobile ? '3' : '4'} w-${isMobile ? '3' : '4'}`} />
          </a>
        </div>
      </div>
    </Card>
  );
};

export default PromotionalBanner;
